import React, { useState, useEffect } from "react";
import "../../styles/header.css";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const Header = () => {
  const [navBg, setNavBg] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <ul className="nav justify-content-end flex-column pt-6 mt-5" >

    <li className="nav-item my-2">
      <a className="nav-link active" aria-current="page" href="#" style={{color:"#19406A"}} >
        Home
      </a>
    </li>
    <li className="nav-item my-2">
      <a className="nav-link" href="#aboutus" style={{color:"#19406A"}}>
        About Us
      </a>
    </li>
    <li className="nav-item my-2">
      <a className="nav-link" href="#contactme" style={{color:"#19406A"}}>
        Contact Us
      </a>
    </li>
    </ul>
  );
  const changeNavBg = () => {
    window.scrollY >= 80 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);
  return (
    <header className={navBg ? "white" : "transparent"} >
      <nav className="navbar px-5 navbar-expand-lg"  >
  <div className="container" >
    <a className="navbar-brand" href="#">
      <img src={require("../../assets/images/GOBITSTransparent.png")} alt="Logo" width="100" height="100" className="d-inline-block align-text-top"  />

    </a>
    <button className="navbar-toggler " onClick={toggleDrawer(true)} style={{backgroundColor:"white"}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" style={{backgroundColor:"white"}}></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
    <ul className="nav justify-content-end">

<li className="nav-item">
  <a className="nav-link active" aria-current="page" href="#" style={{color:"white"}} >
    Home
  </a>
</li>
<li className="nav-item">
  <a className="nav-link" href="#aboutus" style={{color:"white"}}>
    About Us
  </a>
</li>
<li className="nav-item">
  <a className="nav-link" href="#contactme" style={{color:"white"}}>
    Contact Us
  </a>
</li>
</ul>
</div>
  </div>
</nav>
            <Drawer open={open} onClose={toggleDrawer(false)} >
        {DrawerList}
      </Drawer>
    </header>
  );
};

export default Header;
