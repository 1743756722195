import React from "react";
import "../styles/productsAreas.css";
const ProductsAreas = () => {
  return (
    <section id="services">
      <div className=" ">
        <div className="col-md-2 offset-md-5">
          <h1 className="servicesTitle">OUR SERVICES</h1>
        </div>
      </div>
      <div className="row mt-2 g-3">
        <div
          className="col-sm-12 col-md-12 col-lg-4"
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="200"
          data-aos-duration=" 1000"
        >
          <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
            <div class="card-body">
              <img
style={{width:"120px",height:"100px"}}
                src={require("../assets/images/PngItem_1272135.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">Web Development</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                WebSites & WebApps
              </h6>
              <p class="card-text justify-center">
                Are you seeking awe-inspiring, responsive, comprehensive web
                design and development services? Hire the best web development
                company with GreatLike Media. Our expertise and experience have
                helped us develop business-centric and unmatched web solutions
                for our clients.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-4  "
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="400"
          data-aos-duration=" 1000"
        >
          <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
            {" "}
            <div class="card-body">
              
            <img
style={{width:"120px",height:"95px"}}
                src={require("../assets/images/vecteezy_artificial-intelligence.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">AI Integration</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                Build AI Models For Your Business
              </h6>
              <p class="card-text">
                Looking to take your business to the next level? We offers
                full-cycle AI development services. We find the most convenient
                model of working with data for your business and lead the
                process until the production stage. We can offer customized
                artificial intelligence solutions or provide the best
                off-the-shelf ones that fit your needs.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-4  "
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="600"
          data-aos-duration=" 1000"
        >
          <div className="shadow p-3 mb-5 bg-body-tertiary rounded serviceItem card">
            {" "}
            <div class="card-body">
              
            <img
style={{width:"120px",height:"100px"}}
                src={require("../assets/images/PngItem_1478005.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">SEO</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                Search Engine Optimization Services
              </h6>
              <p class="card-text">
                Greatlike Media is an SEO Company that uses a
                series of strategies to generate organic traffic for a website.
                Our firm services businesses of all kinds with the shared goal
                in mind of increasing the exposure of its business. The success
                of increasing these sites viewed helps obtain a high-ranking
                placement in the search results page of search engines such as
                Google, Bing, and Yahoo, benefiting your business in the long
                run.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-4  "
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="1200"
          data-aos-duration=" 1000"
        >
          <div className="shadow p-3 mb-5 bg-body-tertiary rounded serviceItem card">
            {" "}
            <div class="card-body">
            <img
style={{width:"120px",height:"100px"}}
                src={require("../assets/images/PngItem_1247101m.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">Digital Marketing</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                Digital Marketing Services
              </h6>
              <p class="card-text">
                Digital marketing has brought a paradigm shift in the world,
                especially in the way businesses approach their marketing
                activities. You need the best digital marketing
                agency for your business.
                <br />
                Digital marketing is a set of marketing approaches that help
                businesses to drive more traffic to their websites and get more
                organic or paid leads that can be converted into customers.
                <br />
                GreatLike Media is one of the best digital marketing agencies
                that have an ROI-driven and strategic approach to bring the best
                results to the table. Hire the best digital marketing agency.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-4  "
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="900"
          data-aos-duration=" 1000"
        >
          <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
            {" "}
            <div class="card-body">
            <img
style={{width:"120px",height:"100px"}}
                src={require("../assets/images/PngItem_1247062mo.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">Mobile App Development</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                Native Mobile App Development
              </h6>
              <p class="card-text">
                Greatlike Media provides innovative mobile app solutions to keep
                you one step ahead of the competition. One of the best mobile
                app development company . Our app
                developers have expertise in developing all kinds of mobile
                applications.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-4  "
          data-aos="fade-down"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="900"
          data-aos-duration=" 1000"
        >
          <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
            {" "}
            <div class="card-body">
            <img
style={{width:"150px",height:"100px"}}
                src={require("../assets/images/PngItem_4792549s.png")}
                
                alt="VectorStock.com"
              />
              <h5 class="card-title">Social Media</h5>
              <h6 class="card-subtitle mb-2 text-body-secondary">
                Unleash The Potential of Social Media
              </h6>
              <p class="card-text">
                Let our Social Media marketers carve a tailor-made social media
                marketing plan for the platform your target audience uses. Hire
                services of social media marketing .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsAreas;
