import React from 'react'
import "../styles/aboutus.css"
const AboutUs = () => {
  return (
    <section id="aboutus">
    <div className=" ">
      <div className="col-md-2 offset-md-5">
        <h1 className="aboutusTitle">WHO ARE WE?</h1>
      </div>
    </div>
    <div className="row mt-2 g-3">
      <div
        className="col-sm-12 col-md-12 col-lg-6 offset-lg-3 aboutusbody"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <p>We are a team of dedicated professionals with extensive experience.</p>
  </div>
        </div>
    </section>
  )
}
export default AboutUs;