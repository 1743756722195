import './App.css';
import { Routes, Route } from "react-router-dom";
import Main from './pages/Main';
import Pagenotfound from './pages/Pagenotfound';
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="App">
      <Main />
      {/* <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/pagenotfound" element={<Pagenotfound />} />
      </Routes> */}
    </div>
  );
}

export default App;
