import React from "react";
import PageIntro from "../components/PageIntro";
import Header from "../components/common/Header";
import Content from "../components/Content";
import Footer from "../components/common/Footer";
const Main = () => {
  return (
    <>
      <Header />
      <PageIntro />
      <Content />
      <Footer />
      <section style={{backgroundColor:"#19406A"}}>
        <div class="text-center ">
          <span
            style={{
              color: "#C1B283",
              fontWeight: "bold",
              paddingLeft: "8px",
              opacity: 0.5,
            }}
          >
            © 2024 Go Bits{" "}
          </span>
          <span> | </span>{" "}
          <span style={{ opacity: 0.5, color: "#C1B283", }}>All rights reserved</span>
        </div>
      </section>
    </>
  );
};

export default Main;
