import React, { useCallback, useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import "../styles/intro.css";

import { loadSlim } from "@tsparticles/slim";

import { TypeAnimation } from "react-type-animation";

const PageIntro = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <section id="intro" style={{ overflow: "hidden" , textAlign: "center"}}>
      
        <div className="typeanimation">
       
            <h1 style={{    fontSize: "40px",

    color:"white",
    textTransform: "capitalize",
    letterSpacing: "0.7px",
    fontWeight: 600}}>We build software for you to</h1>
    <TypeAnimation
  sequence={[
    // Same substring at the start will only be typed once, initially
    ' Innovate',
    1000,
    ' Grow',
    1000,
    ' Produce',
    1000,
  ]}
  speed={1}
  style={{    fontSize: "40px",

  color:"#025add",
  textTransform: "capitalize",
  letterSpacing: "0.7px",
  fontWeight: 600}}
  repeat={Infinity}
/></div>
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "grab",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                  particles: {
                    number: {
                      value: 4,
                    },
                  },
                },
                repulse: {
                  distance: 140,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
      )}{" "}
  
    </section>
  );
};

export default PageIntro;
