import React from "react";
import { Divider } from "@mui/material";
import ProductsAreas from "./ProductsAreas";
import Portfolio from "./Portfolio";
import Clients from "./Clients";
import Achievements from "./Achievements";
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs"
const Content = () => {
  return (
    <div className="  text-center ">
      {/* <Divider sx={{ margin: "50px" }}> OUR SERVICES</Divider> */}
      <ProductsAreas />
      <AboutUs />
      <Pricing />
      <ContactUs />



      {/* <Divider sx={{ margin: "50px" }}> Our Portfolio</Divider> */}
      {/* <Portfolio /> */}
      {/* <Divider sx={{ margin: "50px" }}> Our Clients</Divider> */}
      {/* <Clients /> */}
      {/* <Divider sx={{ margin: "50px" }}> Our Achievements</Divider> */}
      {/* <Achievements /> */}
      {/* <Divider sx={{ margin: "50px" }}> Pricing</Divider> */}
      
    </div>
  );
};

export default Content;
