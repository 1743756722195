import React from "react";
import "../styles/pricing.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
const Pricing = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <section id="pricing">
      {/* <div className="row "> */}
        <div className="col-md-2 offset-md-5">
          <h1 className="pricingTitle">OUR PRICING</h1>
        </div>
      {/* </div> */}
       {/* <div className="row "> */}
        {/* <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">  */}
    <Box className="pricingTablist">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"

            >
              <Tab label="WebSite Development" value="1"  />
              <Tab label="WebApp Development" value="2" />
              <Tab label="Mobile App Development" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="row mt-2 g-3">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                  <div class="card-body">
                    <h5 class="card-title">Basic</h5>
                    <h5 class="card-title">$2000</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    STARTER
                    </h6>
                    <p class="card-text justify-center">Basic Fullstack Web App</p>
                      <ul class="list-group list-group-flush">
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Design customization.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Include source code.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>4 pages</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions.</p></li>

</ul>
                    
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                <div class="card-body">
                    <h5 class="card-title">Standard</h5>
                    <h5 class="card-title">$4000

</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    ADVANCED
                    </h6>
                    <p class="card-text justify-center">Full Stack Web Application with Authentication and Authorization.</p>
                      <ul class="list-group list-group-flush">
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Design customization.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Include source code.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>7 pages</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions.</p></li>

</ul>
                    
                  </div>
                  <button class="btn btn-primary" onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                <div class="card-body">
                    <h5 class="card-title">Premium</h5>
                    <h5 class="card-title">$6000</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    ALL IN ONE
                    </h6>
                    <p class="card-text justify-center">Advanced + 3rd party oauth, socket.io and admin portal development</p>
                      <ul class="list-group list-group-flush">
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Design customization.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Include source code.</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>10 pages</p></li>
                          <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions.</p></li>

</ul>
                    
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <div className="row mt-2 g-3">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                  <div class="card-body">
                    <h5 class="card-title">$8700</h5>
            
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      STARTUPS
                    </h6>
                    <p class="card-text justify-center">
                      Premium Landing Page + 1-2 Pages + Contact Form + Speed
                      Optimization + Social Icons
                    </p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional website
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Content upload
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>E-commerce functionality
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Payment processing
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Opt-in form
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Autoresponder integration
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Speed optimization
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Hosting setup
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Social media icons
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>2 pages 
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span> 3 Plugins/extensions installation
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Number of products
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                  <div class="card-body">
                    <h5 class="card-title">Standard</h5>
                    <h5 class="card-title">$11,100</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      SMALL BUSINESS
                    </h6>
                    <p class="card-text justify-center">
                      Premium Class Landing Page + 4-5 Pages + Social Icons +
                      Contact Form
                    </p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional website
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Content upload
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>E-commerce functionality
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Payment processing
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Opt-in form
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Autoresponder integration
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Speed optimization
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Hosting setup
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Social media icons
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>5 pages
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>7 Plugins/extensions installation
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Number of products
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                  <div class="card-body">
                    <h5 class="card-title">Premium - All in One</h5>
                    <h5 class="card-title">$16,500</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      LARGE BUSINESS / ECOMMERCE
                    </h6>
                    <p class="card-text justify-center">
                      All in one Web Solution, 10+ Pages Premium SEO+ Speed
                      optimization + Unlimited Revisions + AI Optimization
                    </p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional website
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Responsive design
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Content upload
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>E-commerce functionality
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Payment processing
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Opt-in form
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Autoresponder integration
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Speed optimization
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Hosting setup
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Social media icons
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>10 pages
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>10 Plugins/extensions installation
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>20 products items
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Revisions
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            {" "}
            <div className="row mt-2 g-3">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                  <div class="card-body">
                    <h5 class="card-title">Basic</h5>
                    <h5 class="card-title"> </h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    DESIGN + DEVELOPMENT OF 2 PAGES APP
                    </h6>
                    <p class="card-text justify-center">
                    We will discuss, design & develop basic 2 pages application.
                    </p>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional Android app
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional IOS App
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>App icon
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="xma">&#x2715;</span>Splash screen
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                <div class="card-body">
                    <h5 class="card-title">Standard</h5>
                    <h5 class="card-title"> </h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    DESIGN + DEVELOPMENT OF 5 PAGES APP
                    </h6>
                    <p class="card-text justify-center">
                    We will discuss, design & develop basic 5 pages application
                    </p>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional Android app
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional IOS App
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>App icon
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Splash screen
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Delivery in 10 days
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Delivery in 5 days
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="shadow card p-3 mb-5 bg-body-tertiary rounded serviceItem">
                <div class="card-body">
                    <h5 class="card-title">Premium</h5>
                    <h5 class="card-title"> </h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                    DESIGN + DEVELOPMENT OF 8 PAGES APP
                    </h6>
                    <p class="card-text justify-center">
                    We will discuss, design & develop 8 pages application.
                    </p>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional Android app
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Functional IOS App
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>App icon
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Splash screen
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="priceitems ">
                          <span  className="chma">&#10003;</span>Delivery in 30 days
                        </p>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary"onClick={() => window.location.href = "/#contactme"} >Get A Quote</button>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box> 
       {/* </div> */}
      {/* </div>  */}
    </section>
  );
};

export default Pricing;
